import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../Components/Loader';
import { server } from '../config';

const getPWD = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get("pwd");
}

const utf8ToBase64 = (str) => window.btoa(unescape(encodeURIComponent(str)))

const Zoom = ({match, user}) => {
    const {code} = match.params;
    const pwd = getPWD();
    const [signature, setSignature] = useState("");
    useEffect(() => {
        Axios({
            url: server + `api/v1/zoom/generate/signature/`,
            method: 'POST',
            data: {
                meetingNumber: code
            }
        }).then((res) => {
            setSignature(res.data)
        })
    }, [code])
    if(!user || !signature){
        return <Loader/>
    }
    return(
        <div>
            <iframe frameBorder="none" style={{
                width: '100%',
                height: `calc(100vh - 96px)`,
                minHeight: 240,
                borderRadius: 7
            }} title="Zoom" src={`/meeting.html?name=${encodeURIComponent(utf8ToBase64(user.name))}&mn=${code}&email=&pwd=${pwd}&role=0&lang=ru-RU&signature=${signature}&china=0&apiKey=t2716vOzScSO35TawVVzDg`} />
        </div>
    )
}

export default connect((state) => state)(Zoom);