import React, { useEffect } from "react";
import logo from "../Assets/logo.svg";
import logoNoText from "../Assets/logoNoText.svg";
import smg from "../Assets/smg.svg";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicyItems = [
  {
    title: "Согласие",
    content: [
      {
        type: "text",
        text: "Используя наш сайт, вы тем самым соглашаетесь с нашей Политикой конфиденциальности и соглашаетесь с ее условиями."
      }
    ]
  },
  {
    title: "Информация, которую мы собираем",
    content: [
      {
        type: "text",
        text: "Личная информация, которую вас просят предоставить, и причины, по которым вас просят предоставить ее, будут объяснены в тот момент, когда мы попросим вас предоставить информацию о вас."
      },
      {
        type: "text",
        text: "Если вы обратитесь к нам напрямую, мы можем получить дополнительную информацию о вас, такую как ваше имя, адрес электронной почты, номер телефона, содержание сообщения и / или вложений, которые вы можете отправить нам, и любую другую информацию, которую вы можете предоставить."
      },
      {
        type: "text",
        text: "Когда вы регистрируете учетную запись, мы можем запросить вашу контактную информацию, включая такие элементы, как имя, название компании, адрес, адрес электронной почты и номер телефона."
      }
    ]
  },
  {
    title: "Как мы используем вашу информацию",
    content: [
      {
        type: "text",
        text: "Мы используем информацию, которую собираем, различными способами, в том числе для того, чтобы:"
      },
      {
        type: "ulist",
        list: [
          "Обеспечить, работать и поддерживать наш веб-сайт;",
          "Улучшение, персонализация и расширение нашего веб-сайта;",
          "Понять и проанализировать, как вы используете наш веб-сайт;",
          "Разрабатывать новые продукты, услуги, функции и функциональные возможности;",
          "Связаться с вами напрямую или через одного из наших партнеров, в том числе для обслуживания клиентов, чтобы предоставить вам обновления и другую информацию, касающуюся веб-сайта, а также в маркетинговых и рекламных целях;",
          "Отправить вам письма;",
          "Найти и предотвратить мошенничество;",
          "Лог-файлы;"
        ]
      },
      {
        type: "text",
        text: "Приложение Mirum следует стандартной процедуре использования файлов журнала. Эти файлы регистрируют посетителей, когда они посещают веб-сайты. Все хостинговые компании делают это и часть аналитики хостинговых услуг. Информация, собираемая файлами журналов, включает адреса интернет-протокола (IP), тип браузера, поставщика услуг Интернета (ISP), отметку даты и времени, страницы перехода / выхода и, возможно, количество кликов. Они не связаны с какой-либо информацией, позволяющей установить личность. Целью этой информации является анализ тенденций, администрирование сайта, отслеживание движения пользователей на сайте и сбор демографической информации. Наша политика конфиденциальности была создана с помощью генератора политики конфиденциальности и шаблона политики конфиденциальности."
      }
    ]
  },
  {
    title: "Cookies и веб-маяки",
    content: [
      {
        type: "text",
        text: "Как и любой другой веб-сайт, приложение Mirum использует файлы cookie. Эти файлы cookie используются для хранения информации, включая предпочтения посетителей и страницы на веб-сайте, которые посетитель посещает или посещал. Эта информация используется для оптимизации работы пользователей путем настройки содержимого нашей веб-страницы на основе типа браузера посетителей и/или другой информации."
      }
    ]
  },
  {
    title: "Политика конфиденциальности для партнеров по рекламе",
    content: [
      {
        type: "text",
        text: "Вы можете ознакомиться с этим списком, чтобы найти Политику конфиденциальности для каждого из рекламных партнеров Mirum App."
      },
      {
        type: "text",
        text: "Сторонние рекламные серверы или рекламные сети используют такие технологии, как файлы cookie, JavaScript или веб-маяки, которые используются в их соответствующих рекламных объявлениях и ссылках, которые появляются в приложении Mirum, которые отправляются непосредственно в браузер пользователей. Они автоматически получают ваш IP-адрес, когда это происходит. Эти технологии используются для измерения эффективности их рекламных кампаний и / или для персонализации рекламного контента, который вы видите на посещаемых вами веб-сайтах. Обратите внимание, что приложение Mirum не имеет доступа или контроля над этими файлами cookie, которые используются сторонними рекламодателями."
      }
    ]
  },
  {
    title: "Политика конфиденциальности третьих лиц",
    content: [
      {
        type: "text",
        text: "Политика конфиденциальности Mirum App не распространяется на других рекламодателей или веб-сайты. Поэтому мы советуем вам ознакомиться с соответствующей политикой конфиденциальности этих сторонних рекламных серверов для получения более подробной информации. Это может включать их методы и инструкции о том, как отказаться от определенных вариантов."
      },
      {
        type: "text",
        text: "Вы можете отключить файлы cookie в настройках вашего браузера. Чтобы узнать более подробную информацию об управлении cookie-файлами в определенных веб-браузерах, ее можно найти на соответствующих веб-сайтах браузеров."
      },
      {
        type: "text",
        text: "Права на конфиденциальность CCPA (не продавать мою личную информацию)\nВ соответствии с CCPA, среди прочих прав, калифорнийские потребители имеют право:"
      },
      {
        type: "text",
        text: "Попросите, чтобы компания, которая собирает личные данные потребителя, раскрыла категории и конкретные части личных данных, которые компания собрала о потребителях."
      },
      {
        type: "text",
        text: "Попросите предприятие удалить все личные данные о потребителе, которые компания собрала."
      },
      {
        type: "text",
        text: "Попросите компанию, которая продает личные данные потребителя, не продавать личные данные потребителя."
      },
      {
        type: "text",
        text: "Если вы сделаете запрос, у нас есть один месяц, чтобы ответить вам. Если вы хотите воспользоваться каким-либо из этих прав, свяжитесь с нами."
      },
      {
        type: "text",
        text: "Права по защите данных GDPR\nМы хотели бы убедиться, что вы полностью осведомлены обо всех своих правах на защиту данных. Каждый пользователь имеет право на следующее:"
      },
      {
        type: "text",
        text: "Право на доступ - Вы имеете право запросить копии ваших личных данных. Мы можем взимать небольшую плату за эту услугу."
      },
      {
        type: "text",
        text: "Право на исправление. У вас есть право потребовать, чтобы мы исправили любую информацию, которую вы считаете неточной. Вы также имеете право потребовать, чтобы мы дополнили информацию, которую вы считаете неполной."
      },
      {
        type: "text",
        text: "Право на удаление - Вы имеете право потребовать, чтобы мы стерли ваши личные данные при определенных условиях."
      },
      {
        type: "text",
        text: "Право ограничивать обработку - Вы имеете право потребовать, чтобы мы ограничивали обработку ваших персональных данных при определенных условиях."
      },
      {
        type: "text",
        text: "Право на возражение против обработки - Вы имеете право возразить против нашей обработки ваших персональных данных при определенных условиях."
      },
      {
        type: "text",
        text: "Право на перенос данных. Вы имеете право потребовать, чтобы мы передали собранные нами данные другой организации или непосредственно вам при определенных условиях."
      },
      {
        type: "text",
        text: "Если вы сделаете запрос, у нас есть один месяц, чтобы ответить вам. Если вы хотите воспользоваться каким-либо из этих прав, свяжитесь с нами."
      }
    ]
  },
  {
    title: "Информация о детях",
    content: [
      {
        type: "text",
        text: "Еще одной частью нашего приоритета является добавление защиты детей при использовании Интернета. Мы призываем родителей и опекунов наблюдать, участвовать и/или отслеживать и направлять их деятельность в Интернете."
      },
      {
        type: "text",
        text: "Приложение Mirum сознательно не собирает личную идентификационную информацию от детей в возрасте до 10 лет. Если вы считаете, что ваш ребенок предоставил такую информацию на нашем веб-сайте, мы настоятельно рекомендуем вам немедленно связаться с нами, и мы сделаем все возможное, чтобы быстро удалить такую информацию из наших записей."
      }
    ]
  }
]

const PrivacyPolicy = () => {
  useEffect(() => {
    document.body.classList.remove("dark");
  }, []);
  return (
    <div className={classes.main}>
      <nav>
        <div className={classes.container}>
          <div className={classes.navLeft}>
            <img
              className={classes.logoNoText}
              height={36}
              src={logoNoText}
              alt="Mirum logo"
            />
            <img
              className={classes.logo}
              height={36}
              src={logo}
              alt="Mirum logo"
            />
          </div>
        </div>
      </nav>
      <div className={classes.body}>
        <h3>Политика конфиденциальности платформы Mirum</h3>
        <p>В приложении Mirum, доступном на platform.mirum.kz, одним из наших главных приоритетов
          является конфиденциальность наших пользователей. Этот документ Политики
          конфиденциальности содержит типы информации, которая собирается и регистрируется
          приложением Mirum, и правила ее использования.</p>
        <p>Если у вас есть дополнительные вопросы или вам нужна дополнительная информация о нашей
          Политике конфиденциальности, Вы можете обращаться к нам по указанным контактам.</p>
        <p>Настоящая Политика конфиденциальности применяется только к нашей онлайн-деятельности
          и действительна для посетителей нашего веб-сайта в отношении информации, которой они
          поделились и/или использовали в приложении Mirum. Эта политика не распространяется на
          любую информацию, собранную в автономном режиме или по каналам, отличным от данного
          веб-сайта.</p>
        {PrivacyPolicyItems.map((item, index) => (
          <>
            <h4>{item.title}</h4>
            {item.content.map((el) => (
              <>
                {el.type === "text" ? <p>{el.text}</p> : null}
                {el.type === "ulist" ? <ul>{el.list.map((li, i) => <li key={i.toString()}>{li}</li>)}</ul> : null}
              </>
            ))}
          </>
        ))}
      </div>
      <footer className={classes.footer}>
        <div className={classes.container}>
          <img src={smg} alt="SMG Education" />
          <div>Центр образовательных проектов «SMG Education»</div>
          <div>
            По дополнительным вопросам:{" "}
            <a href="tel:+77078019119">+7 707 801 91 19</a>,{" "}
            <a href="tel:+77754676153">+7 775 467 61 53</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;