import Axios from "axios";
import clsx from "clsx";
import { format, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import moment from "moment";
import "moment/locale/ru";
import React, { useCallback, useEffect, useState } from "react";
import { Collapse } from 'react-collapse';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Button from "../Components/Button";
import Chat from "../Components/Chat";
import Empty from "../Components/Empty";
import Input from "../Components/Input";
import Loader from "../Components/Loader";
import Modal from "../Components/Modal";
import Results from "../Components/Results";
import TeacherResults from "../Components/TeacherResults";
import User from "../Components/User";
import { server } from "../config";
import classes from "./Course.module.css";

import 'react-datepicker/dist/react-datepicker.css';
import { useToasts } from "react-toast-notifications";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

moment.locale("ru")

const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

const filterAsyncronousQuizzes = (quizzes) => {
    const index = quizzes.findIndex((quiz) => !quiz.is_finished)
    if (index === -1) {
        return quizzes.map((quiz) => ({
            ...quiz,
            status: 'finished'
        }))
    }
    return quizzes.map((quiz, i) => {
        if (i < index) {
            return {
                ...quiz,
                status: 'finished',
            }
        } else if (i === index) {
            return {
                ...quiz,
                status: 'active',
            }
        } else {
            return {
                ...quiz,
                status: 'unavailable',
            }
        }
    })
}

const Course = ({ match, token, user, history }) => {
    const [scrolled, setScrolled] = useState(false);
    const [scrolled2, setScrolled2] = useState(false);
    const [results, setResults] = useState({
        asTeacher: false,
        modal: false,
        quiz: null,
    })
    const [eventModal, setEventModal] = useState({
        open: false,
        selected: null,
    })
    const [feed, setFeed] = useState({
        isFetching: true,
        data: []
    })
    const [course, setCourse] = useState({
        isFetching: true,
        data: null
    })
    const [quizzes, setQuizzes] = useState({
        isFetching: true,
        data: []
    })
    const [top10, setTop10] = useState({
        isFetching: false,
        data: []
    })
    const [circularProgress, setCircularProgress] = useState({
        isFetching: true,
        data: null
    })
    const [modal, setModal] = useState(false)
    const isTeacher = user?.email === course.data?.teacher.email
    const getFeed = useCallback(() => {
        Axios({
            url: `${server}api/v1/feeds/`,
            headers: {
                Authorization: `Token ${token}`
            }
        }).then((res) => {
            if (res.data) {
                setFeed({
                    isFetching: false,
                    data: res.data.filter(event => (
                        String(event.course) === match.params.id
                    ))
                })
            }
        })
    }, [match.params.id, token]);

    useEffect(() => { registerLocale('ru', ru); }, []);
    useEffect(() => {
        setCourse({
            isFetching: true,
            data: null
        })
        setQuizzes({
            isFetching: true,
            data: []
        })
        setTop10({
            isFetching: true,
            data: []
        })
        Axios({
            url: server + `api/v1/courses/${match.params.id}/`,
            headers: {
                Authorization: `Token ${token}`
            }
        }).then((res) => {
            if (res.data) {
                setCourse({
                    isFetching: false,
                    data: res.data
                })
            }
        })
        getFeed()
        Axios({
            url: `${server}api/v1/courses/${match.params.id}/quizzes/`,
            headers: {
                Authorization: `Token ${token}`
            }
        }).then((res) => {
            if (res.data) {
                setQuizzes({
                    isFetching: false,
                    data: res.data
                })
            }
        })
        Axios({
            url: `${server}api/v1/top5/`,
            params: {
                course_id: match.params.id
            },
            headers: {
                Authorization: `Token ${token}`
            }
        }).then((res) => {
            if (res.data) {
                setTop10({
                    isFetching: false,
                    data: res.data.top5
                })
            }
        })
        Axios.get(`${server}api/v1/course/results/`, {
            headers: {
                Authorization: `Token ${token}`
            }   
        }).then((res) => {
            if(res.data && res.data.length) {
                const userResult = res.data.filter((course) => course.course == match.params.id)
                console.log(userResult)
                setCircularProgress({
                    isFetching: false,
                    data: userResult[0]
                });
            }
        })
    }, [getFeed, match.params.id, token])
    const seeResults = (quiz) => {
        setResults({
            asTeacher: user.type === "teacher",
            modal: true,
            quiz
        })
    }
    
    return course.isFetching ? (<Skeleton />) : (
        <div className={classes.Course}>
            <div className={classes.courseHeader}>
                <div>
                    <h1 className={classes.courseTitle}>{course.data.name}</h1>
                    <p className={classes.courseDescription}>{course.data.description}</p>
                    <div className={classes.label}>Учитель</div>
                    <User user={course.data.teacher} />
                </div>
                {circularProgress.data && <div style={{ marginRight: '25%'}}>
                    <div style={{ width: 80, height: 80 }}>
                        <CircularProgressbarWithChildren strokeWidth={6} value={circularProgress.data.performance} className={classes.circularProgressbar} styles={{ path: { stroke: '#6c19cf'}}} >
                            <div style={{ fontSize: 18 }}>{circularProgress.data.performance}%</div>
                            <div style={{ position: 'absolute', margin: '0 auto', top: "60%", fontSize: '14px', 
                                color: (circularProgress.data.progress > 0 ? 'green' : circularProgress.data.progress === 0 ? '#66686d' : 'red')
                            }}>{circularProgress.data.progress > 0 ? '+' : ''}{circularProgress.data.progress}</div>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div style={{ width: 80, textAlign: 'center', fontSize: '12px', marginTop: '4px'}}>
                        Текущий прогресс
                    </div>
                </div>}
            </div>
            {course.data.weeks.length ? (<div className={classes.card}>
                <div className={classes.label}>Разделы</div>
                {course.data.weeks.map((week) => (
                    <Week key={week.week} week={week} />
                ))}
            </div>) : null}
            <div className={classes.grid}>
                <div style={{
                    position: 'relative'
                }} className={clsx(classes.card, classes.eventsCard)}>
                    {isTeacher ? <button onClick={() => setEventModal({ open: true, selected: null })} className={classes.topButton}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </button> : null}
                    <div className={clsx(classes.label, {
                        [classes.borderBottom]: scrolled2
                    })}>События</div>
                    {feed.isFetching ? <Loader /> : (feed.data.length ? (
                        <div onScroll={e => {
                            setScrolled2(e.target.scrollTop > 1)
                        }} className={classes.eventsWrap}>
                            <div className={classes.events}>
                                {feed.data.sort((a, b) => (new Date(b.timestamp) - (new Date(a.timestamp)))).map((event) => (
                                    <div style={{
                                        margin: '12px 0',
                                        padding: 16,
                                        position: 'relative'
                                    }} key={event.id} className={classes.card}>
                                        {isTeacher ? <button onClick={() => setEventModal({ selected: event, open: true })} className={classes.topButton}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg></button> : null}
                                        <div className={classes.eventTitle}>{event.title}</div>
                                        <div className={classes.eventBody}>{event.body}</div>
                                        <div className={classes.eventDate}>{moment(event.timestamp).calendar()}</div>
                                        <Button onClick={() => {
                                            const reg = /(zoom.us\/j\/)([0-9]{11})/
                                            const zoom = (reg.test(event.url) && event.url.match(reg)[2]) || "";
                                            let pwd = '';
                                            if (zoom) {
                                                const url = new URL(event.url);
                                                pwd = url.searchParams.get("pwd");
                                                return history.push(`/dashboard/zoom/${zoom}${pwd ? `?pwd=${pwd}` : ''}`)
                                            }
                                            window.location.href = event.url
                                        }} disabled={moment().add(-1, 'day').isAfter(event.timestamp)} size="small">{moment().add(-1, 'day').isAfter(event.timestamp) ? 'Событие прошло' : 'Открыть'}</Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : <Empty height={435} />)}
                </div>
                <div className={clsx(classes.card, classes.quizzesCard)}>
                    <div className={clsx(classes.label, {
                        [classes.borderBottom]: scrolled
                    })}>Тесты</div>
                    <div onScroll={e => {
                        setScrolled(e.target.scrollTop > 1)
                    }} className={classes.quizzesWrap}>
                        {quizzes.isFetching ? <Loader /> : (
                            course.data.type === "default" ? (
                                quizzes.data.map((quiz) => (
                                    <Quiz user={user} seeResults={seeResults} quiz={quiz} key={quiz.id} />
                                ))
                            ) : (
                                filterAsyncronousQuizzes(quizzes.data).map((quiz) => (
                                    <AsynchronousQuiz user={user} seeResults={seeResults} quiz={quiz} key={quiz.id} />
                                ))
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.card}>
                <div className={classes.label}>Топ 10</div>
                {top10.isFetching ? <Loader /> : (top10.data.length ? top10.data.map((student, i) => (
                    <TopItem key={i} i={i} student={student} />
                )) : <Empty />)}
                <Button style={{
                    marginTop: 24
                }} type="default" onClick={() => setModal(true)} size="small">Все участники</Button>
            </div>
            <Modal open={modal} close={() => setModal(false)} title="Участники курса">
                {course.data.students.map((el, i) => (
                    <div key={i} className={classes.student}>
                        <User user={el} small />
                    </div>
                ))}
            </Modal>
            <Modal notMax={results.asTeacher} open={results.modal} close={() => setResults({
                ...results,
                modal: false
            })} className={clsx({
                [classes.asTeacher]: results.asTeacher
            })} width={results.asTeacher ? 1024 : 480} padding={results.asTeacher ? 0 : 24} title="Результаты теста">
                {results.asTeacher ? <TeacherResults open={results.modal} quiz={results.quiz} /> : <Results open={results.modal} quiz={results.quiz} />}
            </Modal>
            <Chat room={course.data.room} />
            <EventModal token={token} onDone={getFeed} close={() => setEventModal({ ...eventModal, open: false })} event={eventModal.selected} open={eventModal.open} id={eventModal.selected?.id || ""} course={match.params.id} />
        </div>
    )
}

const EventModal = ({ id, event, course, onDone, open, close, token }) => {
    const { addToast } = useToasts();
    const [state, setState] = useState({
        title: "",
        body: "",
        url: "",
        timestamp: new Date()
    })
    useEffect(() => {
        if (!event) {
            setState({
                title: "",
                body: "",
                url: "",
                timestamp: new Date()
            })
            return;
        }
        setState({
            title: event.title || "",
            body: event.body || "",
            url: event.url || "",
            timestamp: parse(event.timestamp, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date())
        })
    }, [event])
    const handleChange = field => e => {
        if (!e.target || (typeof e.target.value == 'undefined')) {
            return false
        }
        setState({ ...state, [field]: e.target.value })
    }
    const add = (edit) => {
        if (!state.title || !state.body || !state.url) {
            return addToast("Заполните все поля", { appearance: 'error' })
        }
        let date;
        try {
            date = format(state.timestamp, "yyyy-MM-dd'T'HH:mm:ssxxx")
        } catch (err) {
            return addToast("Неверная дата", { appearance: 'error' })
        }
        Axios({
            url: server + (edit ? `api/v1/feeds/${id}/` : `api/v1/feeds/`),
            method: edit ? 'PATCH' : 'POST',
            headers: {
                Authorization: `Token ${token}`
            },
            data: {
                ...state,
                timestamp: date,
                course
            }
        }).then(() => {
            setState({
                title: "",
                body: "",
                url: "",
                timestamp: new Date()
            })
            onDone();
            close();
        }).catch(() => {
            addToast("Ошибка", { appearance: 'error' })
        })
    }
    const remove = () => {
        Axios({
            url: server + `api/v1/feeds/${id}`,
            method: 'DELETE',
            headers: {
                Authorization: `Token ${token}`
            },
        }).then(() => {
            setState({
                title: "",
                body: "",
                url: "",
                timestamp: new Date()
            })
            onDone();
            close();
        }).catch(() => {
            addToast("Ошибка", { appearance: 'error' })
        })
    }
    return (
        <Modal title={id ? 'Редаткировать событие' : 'Добавить событие'} open={open} close={close}>
            <form onSubmit={e => {
                e.preventDefault()
                add(!!id);
            }}>
                <Input type="text" value={state.title} onChange={handleChange("title")} placeholder="Заголовок" />
                <div className={classes.spacer} />
                <Input type="textarea" value={state.body} onChange={handleChange("body")} placeholder="Текст" />
                <div className={classes.spacer} />
                <Input type="text" value={state.url} onChange={handleChange("url")} placeholder="https://zoom.us/j/XXXXXXXXXX" />
                <div className={classes.spacer} />
                <DatePicker
                    calendarContainer={({ className, children }) => (
                        <div
                            style={{
                                zIndex: 100,
                                position: 'relative',
                            }}
                            className={className}
                        >
                            <div className="react-datepicker_arrow" />
                            {children}
                        </div>
                    )}
                    timeCaption="Время"
                    selected={state.timestamp}
                    onChange={(date) => setState(s => ({ ...s, timestamp: date }))}
                    locale="ru"
                    showTimeSelect
                    timeFormat="p"
                    showPopperArrow
                    timeIntervals={15}
                    dateFormat="Pp"

                    customInput={<Input placeholder="Дата" />}
                />
                <div className={classes.spacer} />
                <Button>{id ? 'Сохранить' : 'Добавить'}</Button>
            </form>
            {id ? (
                <>
                    <div className={classes.spacer} />
                    <Button onClick={remove} type="outline">Удалить</Button>
                </>
            ) : null}
        </Modal>
    )
}

const Skeleton = () => (
    <div className={classes.CourseSkeleton}>
        <div className={classes.sHeader}>
            <div className={classes.sHeaderTitle} />
            <div className={classes.sHeaderDescription} />
            <div className={classes.label}>Учитель</div>
            <User />
        </div>
        <div className={classes.card}>
            <div className={classes.label}>Разделы</div>
            <Loader height={128} />
        </div>
        <div className={classes.grid}>
            <div className={classes.card}>
                <div className={classes.label}>События</div>
                <Loader height={128} />
            </div>
            <div className={classes.card}>
                <div className={classes.label}>Тесты</div>
                <Loader height={128} />
            </div>
        </div>
        <div className={classes.card}>
            <div className={classes.label}>Топ 10</div>
            <Loader height={128} />
        </div>
    </div>
)

const TopItem = ({ student, i }) => (
    <div className={classes.topItem}>
        <div className={classes.topItemName}>
            {i * 1 + 1}. {student.user}
        </div>
        <div className={classes.topItemValue}>
            {student.total_sum}
        </div>
    </div>
)

const Quiz = ({ quiz, seeResults, user }) => {
    const quizInformation = quiz.quiz;
    const history = useHistory();
    return (
        <div className={classes.quiz}>
            <div className={classes.quizTitle}>{quizInformation.description}</div>
            <div className={classes.quizInfo}>
                <div className={classes.quizInfoLabel}>Начало:</div>
                <div className={classes.quizInfoValue}>{moment(quizInformation.start_time).format('lll')}</div>
            </div>
            <div className={classes.quizInfo}>
                <div className={classes.quizInfoLabel}>Окончание:</div>
                <div className={classes.quizInfoValue}>{moment(quizInformation.end_time).format('lll')}</div>
            </div>
            <div className={classes.quizInfo}>
                <div className={classes.quizInfoLabel}>Время:</div>
                <div className={classes.quizInfoValue}>{quizInformation.duration} мин</div>
            </div>
            <div className={classes.quizButtons}>
                <Button disabled={(user && user.type === "teacher") || moment().isAfter(quizInformation.end_time) || quiz.is_finished === true} onClick={() => history.push(`quiz/${quizInformation.id}`)} size="small">Пройти тест</Button>
                <Button onClick={() => seeResults(quizInformation)} size="small">Ответы</Button>
            </div>
        </div>
    )
}

const LockIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 32 32"><path d="M25 12h-1V8.184C24 3.595 20.68 0 15.963 0 11.227 0 8 3.671 8 8.184V12H7c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4V16c0-2.206-1.794-4-4-4zM10 8.184C10 4.775 12.33 2 15.963 2 19.559 2 22 4.716 22 8.184V12H10V8.184zM27 28c0 1.102-.898 2-2 2H7c-1.103 0-2-.898-2-2V16c0-1.102.897-2 2-2h18c1.102 0 2 .898 2 2v12zM16 18a1.994 1.994 0 00-1 3.723V25a1 1 0 002 0v-3.277c.595-.346 1-.985 1-1.723a2 2 0 00-2-2z"></path></svg>

const AsynchronousQuiz = ({ quiz, seeResults, user }) => {
    const quizInformation = quiz.quiz;
    const history = useHistory();
    return (
        <div className={classes.quiz}>
            <div className={classes.quizTitle}>{quizInformation.description}</div>
            <div className={classes.quizInfo}>
                <div className={classes.quizInfoLabel}>Время:</div>
                <div className={classes.quizInfoValue}>{quizInformation.duration} мин</div>
            </div>
            <div className={classes.quizButtons}>
                <Button disabled={(user && user.type === "teacher") || quiz.status !== 'active'} icon={quiz.status === "unavailable" ? <LockIcon /> : null} onClick={() => history.push(`quiz/${quizInformation.id}`)} size="small">
                    {quiz.status === 'finished' ? (
                        'Тест пройден'
                    ) : quiz.status === 'active' ? (
                        'Пройти тест'
                    ) : (
                        'Тест недоступен'
                    )}
                </Button>
                <Button onClick={() => seeResults(quizInformation)} size="small">Ответы</Button>
            </div>
        </div>
    )
}

const Week = ({ week }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={clsx(classes.week, {
            [classes.open]: open
        })}>
            <div onClick={() => setOpen(!open)} className={classes.weekHeader}>
                <span>{week.week_name}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><path d="M6 9l6 6 6-6" /></svg>
            </div>
            <Collapse theme={{ collapse: classes.collapse, content: classes.collapseContent }} isOpened={open}>
                <p className={classes.weekDescription}>{week.week_description}</p>
                {week.lessons.map((lesson) => {
                    return (lesson.is_shown ? (
                        <Lesson lesson={lesson} key={lesson.id} />
                    ) : null)
                })}
            </Collapse>
        </div>
    )
}

const Lesson = ({ lesson }) => (
    <div className={classes.lesson}>
        <div className={classes.lessonNumber}>№{lesson.number}</div>
        <div className={classes.lessonName}>{stripHtml(lesson.name)}</div>
        <p>{stripHtml(lesson.description)}</p>
        <Link style={{
            display: "block",
            width: "fit-content"
        }} to={`lesson/${lesson.id}/`}><Button size="small" style={{
            width: "fit-content"
        }}>Открыть</Button></Link>
    </div>
)

export default connect(state => state)(Course)