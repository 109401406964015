import Axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { bindActionCreators } from "redux";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import Index from "./Pages/Index";
import Login from "./Pages/Login";
import Logout from "./Pages/Logout";
import Pay from "./Pages/Pay";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Cart from "./Pages/Store/Cart";
import Store from "./Pages/Store/Index";
import { login } from "./Redux/actions";
import ScrollToTop from "./ScrollToTop";
import { server } from "./config";

const App = ({ token, user, login }) => {
  const [logged, setLogged] = useState(token);
  useEffect(() => {
    if (token && !user) {
      Axios({
        url: server + `api/v1/user_info/`,
        headers: {
          Authorization: `Token ${token}`
        }
      }).then((res) => {
        if (res.data) {
          login(res.data, token, true)
          setLogged(true);
        }
      })
    }
  }, [login, token, user])
  return (
    <BrowserRouter>
      <Router>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="bottom-right"
        >
          <ScrollToTop>
            <Switch>
              <Route
                exact
                path="/"
                component={Index}
              />
              <Route exact path="/login" component={Login} />
              <Route path="/privacy_policy" component={PrivacyPolicy} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/logout" exact component={Logout} />
              <Route path="/pay" exact component={Pay} />
              <Route path="/store" exact component={Store} />
              <Route path="/store/cart" exact component={Cart} />
            </Switch>
          </ScrollToTop>
        </ToastProvider>
      </Router>
    </BrowserRouter>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: bindActionCreators(login, dispatch)
})

export default connect(state => state, mapDispatchToProps)(App);
